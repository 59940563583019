import axios from 'axios.js';

export default class DepotSetupApi {
  constructor() {
    this.api_token = null;
    this.client = null;
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  init = () => {
    this.client = axios.create({
      timeout: 120000,
    });

    return this.client;
  };

  getDepoList = (params, orgId) => {
    return this.init().get('/api/depot/show_all/', { params: params });
  };

  getPermittedDepotList=(params)=>{
    
    return this.init().post('/api/user-depot-permission/permitted', { params: params });

  }

  
  // getPermittedDepotList2=(data)=>{
    
  //   return this.init().post('/api/user-depot-permission/show_depot_list', data);

  // }

  async getPermittedDepotList2(data) {
    try {
      const response = await this.init().post('/api/user-depot-permission/show_depot_list', data);
      return response;
    } catch (error) {
      console.error('Error fetching assigned permission:', error);
      throw error;
    }
  }

  getPermittedParentDepotList=(params)=>{

    return this.init().get('/api/depot/parent_list', { params: params });



  }




  countDepotChargerVech = (params, depotId) => {
    
    return this.init().get('/api/depot/count_vech_charger/' + depotId, { params: params });
  };

  getChargerStatus = (params) => {
    return this.init().get('/api/dash/all_charger_status', { params: params });
  };

  getChargersStatusList = (params) => {
    return this.init().get('/api/charger/all_charger_status_list', { params: params });
  };
}
