
import { useContext, useEffect, useState } from 'react';


import { Provider } from 'react-redux';
import { useLocation, useRoutes } from 'react-router-dom';
import { MatxTheme } from './components';
import { AuthProvider } from './contexts/JWTAuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { Store } from './redux/Store';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from './ThemeProvider';
import routes from './routes';
import { ToastContainer } from 'react-toastify';
import useAuth from './hooks/useAuth';
import DashboardApi from './views/dashboard/DashboardApi';
import { GlobalStoreContext, GlobalStoreProvider } from './GlobalStoreProvider';



const App = () => {
  const content = useRoutes(routes);
  const { logout, user } = useAuth();
  const location=useLocation();
  const api=new DashboardApi();
  // const {store, setKeyValue, getValue}=useContext(GlobalStoreContext);

  // console.log("store",store);


  useEffect(()=>{

    console.log(location);
    


    try{

      api
      .addPageInfoLog({endpoint:location.pathname})
      .then((response) => {
				console.log("Cannot load event data", response);
			})
			.catch((e) => {
				console.log("Cannot load event data", e);
			});
      
    } catch(e){
      console.log("Cannot load event data", e);

    }


  },[location.pathname]);
  


  useEffect(() => {

    const resetLoginDatetime = (event) => {

      

      // console.log(event);
      if (localStorage.getItem('loginTimestamp') !== undefined && localStorage.getItem('loginTimestamp') !== null) {

        const loginTimestamp = localStorage.getItem('loginTimestamp');
        const currentTime = new Date();
        const loginTime = new Date(loginTimestamp); // Convert the stored timestamp back to a Date object

        // Calculate the difference in minutes
        const timeDifference = (currentTime - loginTime) / (1000 * 60); // Difference in minutes

        if (timeDifference > 120) {
          // Add your logic here, e.g., log out the user, reset session, etc.
          logout();
        }
        else localStorage.setItem('loginTimestamp', new Date());

      }

    };

    // List of events to detect activity
    const events = ['mousemove', 'mousedown', 'keydown', 'scroll', 'touchstart', 'wheel'];

    // Add event listeners for user activity
    events.forEach((event) => window.addEventListener(event, resetLoginDatetime));

    // Set initial timer
    resetLoginDatetime();

    // Cleanup event listeners and timer on unmount
    return () => {
      events.forEach((event) => window.removeEventListener(event, resetLoginDatetime));
    };
  }, []);





  return (
    <ThemeProvider>
      <GlobalStoreProvider>
      <Provider store={Store}>
        <SettingsProvider>
          <MatxTheme>
            <AuthProvider>{content}</AuthProvider>
          </MatxTheme>
        </SettingsProvider>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </Provider>
      </GlobalStoreProvider>
    </ThemeProvider>
  );
};

export default App;
