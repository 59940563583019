import React, { createContext, useState } from 'react';

// Create Context
export const GlobalStoreContext = createContext();

// Create Provider Component
export const GlobalStoreProvider = ({ children }) => {
  const [store, setStore] = useState(JSON.parse(localStorage.getItem("store"))||{}); // Initialize with an empty key-value object


  console.log(store);

  // Function to update or add a key-value pair
  const setKeyValue = (key, value) => {
    setStore((prevStore) => ({ ...prevStore, [key]: value }));
    localStorage.setItem("store",JSON.stringify(({ ...store, [key]: value })))
  };

  // Function to retrieve a value by key
  const getValue = (key) => store[key];

  return (
    <GlobalStoreContext.Provider value={{ store, setKeyValue, getValue }}>
      {children}
    </GlobalStoreContext.Provider>
  );
};
