import React from 'react';
import { useThemeContext } from 'app/ThemeProvider';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { Span } from 'app/components/Typography';

const ThemeSwitch = () => {
  const { darkTheme, toggleTheme } = useThemeContext();

  return (
    <a onClick={toggleTheme}>
      {/* <input
        type="checkbox"
        id="darkThemeSwitch"
        checked={darkTheme}
        onChange={toggleTheme}
        style={{ display: 'none' }} // Hide the default checkbox
      /> */}
      {/* Use ToggleOffIcon when darkTheme is off and DarkModeIcon when darkTheme is on */}
      {darkTheme ? <DarkModeIcon style={{ color:'#F2E34C' }}  /> : <WbSunnyIcon style={{ color:'#000' }}  />}

        &nbsp;&nbsp;
      <Span style={{ color: darkTheme ? '#f2e34c' : '#000' }}>Theme</Span>

    </a>
  );
};

export default ThemeSwitch;
