// import { Icon, IconButton } from '@mui/material';
// import { styled, useTheme } from '@mui/system';
// import { topBarHeight } from 'app/utils/constant';
// import React, { useState } from 'react';

// const SearchContainer = styled('div')(({ theme }) => ({
//   position: 'absolute',
//   top: 0,
//   left: 0,
//   zIndex: 9,
//   width: '100%',
//   display: 'flex',
//   alignItems: 'center',
//   height: topBarHeight,
//   background: theme.palette.primary.main,
//   color: theme.palette.text.primary,
//   '&::placeholder': {
//     color: theme.palette.text.primary,
//   },
// }));

// const SearchInput = styled('input')(({ theme }) => ({
//   width: '100%',
//   border: 'none',
//   outline: 'none',
//   fontSize: '1rem',
//   paddingLeft: '20px',
//   height: 'calc(100% - 5px)',
//   background: theme.palette.primary.main,
//   color: theme.palette.text.primary,
//   '&::placeholder': { color: theme.palette.text.primary },
// }));

// const MatxSearchBox = () => {
//   const [open, setOpen] = useState(false);
//   const toggle = () => {
//     setOpen(!open);
//   };

//   const { palette } = useTheme();
//   const textColor = palette.text.primary;

//   return (
//     <React.Fragment>
//       {!open && (
//         <IconButton onClick={toggle}>
//           <Icon sx={{ color: textColor }}>search</Icon>
//         </IconButton>
//       )}

//       {open && (
//         <SearchContainer>
//           <SearchInput type="text" placeholder="Search here..." autoFocus />
//           <IconButton onClick={toggle} sx={{ mx: 2, verticalAlign: 'middle' }}>
//             <Icon sx={{ color: textColor }}>close</Icon>
//           </IconButton>
//         </SearchContainer>
//       )}
//     </React.Fragment>
//   );
// };

// export default MatxSearchBox;



import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { AppBar, Grid, IconButton, InputBase, Toolbar, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';



const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '50rem',
      },
    },
  },
}));





export default function MatxSearchBox() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box

      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : '100%', }}
      role="presentation"
    // onClick={toggleDrawer(anchor, false)}
    // onKeyDown={toggleDrawer(anchor, false)}
    >

      <AppBar position="static" >
        <Toolbar>


          <Grid container>
            <Grid item lg={11}>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ 'aria-label': 'search' }}
                />
              </Search>

            </Grid>

            <Grid item lg={1}>

              <Button variant='contained' color='success' >
                Search
              </Button>

            </Grid>
          </Grid>

        </Toolbar>
      </AppBar>



    </Box>
  );

  return (
    <Grid sx={{display: { xs: 'none', sm: 'none', lg: 'flex', md: 'none' }}}>


      <IconButton onClick={toggleDrawer("top", true)}>
        <SearchIcon />
      </IconButton>
      <Drawer
        anchor={"top"}
        open={state["top"]}
        onClose={toggleDrawer("top", false)}
      >
        {list("top")}
      </Drawer>

    </Grid>
  );
}
