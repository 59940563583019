import axios from 'axios.js';

export default class ChargerApi {
  constructor() {
    this.api_token = null;
    this.client = null;
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  init = () => {
    this.client = axios.create({
      timeout: 120000,
    });

    return this.client;
  };
  getAllOcppTag = (params) => {
    return this.init().get('/api/ocpp-tag/',{params:params});
  };

  getChargerStatus = (params) => {
    return this.init().get('/api/dash/charger_status', { params: params });
  };
  getAllChargerByDepotId = () => {
    return this.init().get('/api/charger/');
  };
  getChargersStatusList = (params) => {
    return this.init().get('/api/charger/charger_status_list', { params: params });
  };

  getChargersRuntime = (params) => {
    return this.init().get('/api/charger/charger_runtime', { params: params });
  };

  getChargergingProfile = (params) => {
    return this.init().get('/api/charger/show_charge_profiles', { params: params });
  };

  getChargerConnectorList = (params, chargerId) => {
    return this.init().get('/api/charger/charger_connector_list/' + chargerId, { params: params });
  };

  getVehicleTags = (params) => {

    return this.init().get('/api/vehicle/show_vehicle_tags', { params: params });
  };

  getAllOcppTag = (params) => {
    return this.init().get('/api/ocpp-tag/',{params:params});
  };

  getReservedChargerList = (params, chargerId) => {
    return this.init().get('/api/charger/show_reservation_list', { params: params });
  };

  getChargerPortView = (params, chargerId, connectorId, vehicleId) => {
    return this.init().get('/api/charger/charger_port/' + chargerId, { params: params });
  };

  getChargerUsesGraph = (params) => {
    return this.init().get('/api/charger/charger_uses/', { params: params });
  };

  getChargerWeeklyConsumtion = (params) => {
    return this.init().get('/api/report/weekly_utilization', { params: params });
  };

  getChargerWeeklyTransaction = (params) => {
    return this.init().get('/api/report/weekly_transactions', { params: params });
  };

  

  // OCCP Commands
  remoteStart = (sCHARGEBOXID, idTag, params, connectorId) => {
    sCHARGEBOXID = Number(sCHARGEBOXID);
    return this.init().get(
      '/api/ocppcommands/' + sCHARGEBOXID + '/startSession/' + idTag + '/' + connectorId,
      {
        params: params,
      }
    );
  };

  remoteStop = (sCHARGEBOXID, connectorId) => {
    sCHARGEBOXID = Number(sCHARGEBOXID);
    return this.init().get('/api/ocppcommands/' + sCHARGEBOXID + '/stopSession/' + connectorId, {

    });
  };

  changeAvailability = (sCHARGEBOXID, connectorId, availableType, params) => {
    sCHARGEBOXID = Number(sCHARGEBOXID);
    return this.init().get(
      '/api/ocppcommands/' +
      sCHARGEBOXID +
      '/changeAvailability/' +
      connectorId +
      '/' +
      availableType,
      {
        params: params,
      }
    );
  };

  clearCache = (sCHARGEBOXID, params) => {
    return this.init().get('/api/ocppcommands/clearCache/' + sCHARGEBOXID, {
      params: params,
    });
  };

  resetCharger = (sCHARGEBOXID, resetType, params) => {
    sCHARGEBOXID = Number(sCHARGEBOXID);
    return this.init().get('/api/ocppcommands/resetCharger/' + sCHARGEBOXID + '/' + resetType, {
      params: params,
    });
  };

  unlockConnector = (sCHARGEBOXID, connectorId, params) => {
    sCHARGEBOXID = Number(sCHARGEBOXID);
    return this.init().get('/api/ocppcommands/unlock/' + sCHARGEBOXID + '/' + connectorId, {
      params: params,
    });
  };

  cancelReservation = (sCHARGEBOXID, reservationId, params) => {

    sCHARGEBOXID = Number(sCHARGEBOXID);
    reservationId = Number(reservationId);
    return this.init().get(
      '/api/ocppcommands/cancelReservation/' + sCHARGEBOXID + '/' + reservationId,
      {
        params: params,
      }
    );
  };

  reserveNow = (data) => {
    return this.init().post('/api/ocppcommands/reserveNow', data);
  };

  changeConfiguration = (data) => {
    return this.init().post('/api/ocppcommands/changeConfiguration', data);
  };

  sendLocalList = (data) => {
    return this.init().post('/api/ocppcommands/setLocalList', data);
  };

  getLocalList = (sCHARGEBOXID, params) => {
    sCHARGEBOXID = Number(sCHARGEBOXID);
    return this.init().get('/api/ocppcommands/' + sCHARGEBOXID + '/getlocallist', {
      params: params,
    });
  };

  getConfiguration = (data) => {
    return this.init().post('/api/ocppcommands/getconfiguration', data);
  };


  getLastUpdatedConfiguration = (params) => {
    return this.init().get('/api/configuration/', {params:params});
  };

  updateFirmware = (data) => {
    return this.init().post('/api/ocppcommands/updateFirmware', data);
  };

  getDiagnostics = (data) => {
    return this.init().post('/api/ocppcommands/getDiagnostics', data);
  };

  dataTransfer = (data) => {
    return this.init().post('/api/ocppcommands/dataTransfer', data);
  };

  triggerMessage = (data) => {
    return this.init().post('/api/ocppcommands/triggerMessage', data);
  };

  updateChargingProfile = (data) => {
    return this.init().post('/api/ocppcommands/updateChargingProfile', data);
  };

  clearChargingProfile = (data) => {
    return this.init().post('/api/ocppcommands/clearChargingProfile', data);
  };

  showChargerStMonitorReport = (data) => {
    return this.init().post('/api/report/show_charging_st_monitor', data);
  };
  getLastMeterValues = (params) => {
    return this.init().get('/api/charger/lastMeterValues', {params:params});
    
  }
  getTransactionLog = (params) => {
    return this.init().get('/api/report/transaction_log', {params:params});
  }


  getChargerLogs = (params) => {
    return this.init().get('/api/charger/logs/'+params);
  }

  
  getChargerListByDepotId = (params) => {
    return this.init().get('/api/charger/depotIdList',{params:params});
  }
}
