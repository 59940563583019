import React, { Fragment, useEffect, useState } from 'react';

import MenuItem from '@mui/material/MenuItem';

// import './MapObject.css';
import { useThemeContext } from 'app/ThemeProvider';
import { Avatar, Card, Grid, styled, useTheme } from '@mui/material';

// import MapObjectView from './shared/MapObjectView';
// import { Map, MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';

import DepotSetupApi from '../setdepot/DepotSetupApi';

import FormControl from '@mui/material/FormControl';
import DashboardApi from '../dashboard/DashboardApi';
import InputLabel from '@mui/material/InputLabel';

import Select from '@mui/material/Select';

import UserApi from '../cmsmgmt/usrmgmt/UserApi';
import { MatxMenu } from 'app/components';
import { Span } from 'app/components/Typography';


const UserMenu = styled(Box)(() => ({
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: 24,
  '& span': { margin: '0 8px' },
}));

const StyledItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: 185,
  width: '100%',

  '& a': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  },
  '& span': { marginRight: '10px', color: theme.palette.text.primary },
}));

export default function DepotZone() {
  const [age, setAge] = React.useState('');


  const { darkTheme } = useThemeContext();
  const { palette } = useTheme();
  const [depos, setDepot] = useState([]);
  const api1 = new DepotSetupApi();
  const [depotId, setDepotId] = React.useState(0);
  const [depotName, setDepotName] = React.useState('');
  const [position, setCoords] = useState([28.5355, 77.391]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [zoneconfirmation, setZoneConfirmOpen] = useState(false);
  const [zone, setZone] = useState([]);


  const [parentDepotList, setParentDepotList] = useState([{ name: 'All', id: 0 }]);
  const [parentDepotId, setParentDepotId] = useState(0);



  // { id: 5, name: "All", key: "all" }, { id: 1, name: "Northc", key: "NZ" }, { id: 2, name: "West", key: "WZ" }, { id: 3, name: "South", key: "SZ" },
  //   { id: 3, name: "East", key: "EZ" }

  const [depotZoneId, setDepotZoneId] = React.useState("all");
  const [totalVehicle, setTotalVehicle] = React.useState(0);
  const [totalCharger, setTotalCharger] = React.useState(0);
  const [charging, setCharging] = useState(0);
  const [charged, setCharged] = useState(0);
  const [chargedDelay, setChargedDelay] = useState(0);
  const [statusData, setStatusData] = useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const api2 = new DashboardApi();

  const handleChangeZone = async (event, index) => {
    setDepotZoneId(event.target.value);
    localStorage.setItem('selected_zone', event.target.value);
    localStorage.setItem('selected_zone_name', event.target.value);

    // if (event.target.value === 'all') {
    //   if (depos.length <= 0) {
    //     return;
    //   }
    //   var depot = depos[0];

    //   fetchAssignedPermission(depot
    //     , JSON.parse(localStorage.getItem("user")).username);
    //   localStorage.setItem('selected_depot', depot.depotId);
    //   localStorage.setItem('selected_depot_name', depot.depotName);

    //   countDepotChargerVech(depot.depotId);
    //   setCoords([depot.latitude, depot.longitude]);

    // } else {
    //   var depots = depos.filter(d => d.depotZone === event.target.value);
    //   if (depots.length <= 0) {
    //     return;
    //   }
    //   var depot = depots[0];
    //   fetchAssignedPermission(depot
    //     , JSON.parse(localStorage.getItem("user")).username);
    //   localStorage.setItem('selected_depot', depot.depotId);
    //   localStorage.setItem('selected_depot_name', depot.depotName);
    //   countDepotChargerVech(depot.depotId);
    //   setCoords([depot.latitude, depot.longitude]);
    // }
    fetchDepotListZoneWise(event.target.value);
    // window.location.reload();
  };


  const handleChange = async (event, index) => {
    setDepotId(event.target.value);
    setDepotName(index.props.children);
    var depot = depos.filter(d => d.depotId === event.target.value);

    const response = await fetchAssignedPermissionAsync(depot[0]
      , JSON.parse(localStorage.getItem("user")).username);

    localStorage.setItem('selected_depot', event.target.value);
    localStorage.setItem('selected_depot_name', index.props.children);
    countDepotChargerVech(event.target.value);
    depos.map(function (d) {
      if (d.id === event.target.value) {
        setCoords([d.latitude, d.longitude]);
        return;
      }
    });

    window.location.reload();
  };


  const fetchAssignedPermissionAsync = async (depot, email) => {
    try {
      const api = new UserApi();
      const response = await api.getAssignedPermission({ id: depot.id, username: email });
      localStorage.setItem('permission', JSON.stringify(response.data));
      return response.data == null ? " " : JSON.stringify(response.data);
    } catch (error) {
      console.error('Cannot load event data', error);
      throw error;
    }
  };

  const fetchAssignedPermission = (depot, email) => {
    const api = new UserApi();
    debugger;
    api
      .getAssignedPermission({ id: depot.id, username: email })
      .then((response) => {
        console.log(response);
        localStorage.setItem('permission', JSON.stringify(response.data));

      })
      .catch((e) => {
        // console.log('Cannot load event data', e);
      });
  }


  useEffect(() => {
    debugger;
    const user = JSON.parse(localStorage.getItem("user"));
    if (user.role[0].toString().includes("ROLE_ZONAL")
      ||
      user.role[0].toString().includes("ROLE_SUPERADMIN")) {
      setZoneConfirmOpen(true);
    }
    fetchDepotList();
    fetchPermittedParentDepotList();

    if (localStorage.getItem("parentDepotId") === undefined||localStorage.getItem("parentDepotId") === null) {
      localStorage.setItem("parentDepotId", 0);
    } else setParentDepotId(localStorage.getItem("parentDepotId"));


  }, []);





  useEffect(() => {
    // console.log("depos"+depos.length);

    if (localStorage.getItem("selected_depot") !== undefined) {
      setDepotName(localStorage.getItem("selected_depot_name")==="undefined"?"":localStorage.getItem("selected_depot_name"));
      setDepotId(localStorage.getItem("selected_depot"));
    }
    else if (depos.length > 0 && depotId !== 0) {
      localStorage.setItem('selected_depot', depos[0].depotId);
      localStorage.getItem("selected_depot_name", depos[0].depotName);
      setDepotId(depos[0].depotId);

    }
    check_prv_depot();


  }, [depos.length]);



  const fetchPermittedParentDepotList = () => {

  debugger;

    const user = JSON.parse(localStorage.getItem("user"));
    const orgId = localStorage.getItem("orgId");

    api1
      .getPermittedParentDepotList({ userId: user.id, orgId: orgId })
      .then(({ data }) => {
        console.log(data)

        let depotSZoneId = localStorage.getItem("selected_zone");
        let d=data;

        if(depotSZoneId&&depotSZoneId!=='all')
           d=data.filter(o=>o.depotZone===depotSZoneId)
        
        setParentDepotList([...parentDepotList, ...d]);
      })
      .catch((e) => {
        console.log('Request history data', e);
      });


  }






  useEffect(() => {
    // const source = axios.CancelToken.source();
    // const intervalId = setInterval(() => {
    //   let isCanceled = false;
    api2
      .getChargerStatus()
      .then(({ data }) => {
        // if (!isCanceled) {
        // console.log(data)
        setStatusData(data);
        // }
      })
      .catch((e) => {
        console.log('Request history data', e);
      });
    // }, 5000);

    // return () => {
    // clearInterval(intervalId);
    // source.cancel();
    // isCanceled = true;
    // };
  }, [depos.length]);

  const handleSetupDepot = () => {
    setConfirmOpen(false);
  };

  const fetchDepotListZoneWise = async (zoneId) => {

    debugger;

    const user = JSON.parse(localStorage.getItem("user"));
    // console.log(user.username + " " + depos);
    if (user === undefined || user === null) {
      return alert("Login again");
    }
    var state = {};
    state.username = user.username.trim();
    debugger;

    const response = await api1.getPermittedDepotList2(state);

    if (response.data.body.length <= 0) {
      // setConfirmOpen(true);
      return; // Early return if no depots are found
    }

    var depot = {};

    let depots=response.data.body;

    if (zoneId !== 'all') {
      depots=depots.filter(d => d.depotZone === zoneId);
    }

    let parentDepotId=localStorage.getItem("parentDepotId");


    if (parentDepotId&&parseInt(parentDepotId) !== 0)
      depots = depots.filter(depot => depot.parentDepotId === parseInt(parentDepotId));

      setDepot(depots);
      depot = depots[0]||{};
    

    const responseprm = await fetchAssignedPermissionAsync(depot
      , JSON.parse(localStorage.getItem("user")).username);

    localStorage.setItem('selected_depot', depot.depotId);
    localStorage.setItem('selected_depot_name', depot.depotName);
    countDepotChargerVech(depot.depotId);
    setCoords([depot.latitude, depot.longitude]);
    window.location.reload()


  };



  const fetchDepotList = () => {
    
    setZone([]);

    debugger;

    const user = JSON.parse(localStorage.getItem("user"));
    // console.log(user.username + " " + depos);
    if (user === undefined || user === null) {
      return alert("Login again");
    }
    var state = {};
    state.username = user.username.trim();
    api1
      .getPermittedDepotList2(state)
      .then((response) => {

        setDepot(response.data.body);
        localStorage.setItem("permittedDepot", JSON.stringify(response.data.body));
        if (response.data.body.length <= 0) {
          setConfirmOpen(true);
        }

        var depotSZoneId = localStorage.getItem("selected_zone");
        depotSZoneId = depotSZoneId === null ? "all" : depotSZoneId;
        setDepotZoneId(depotSZoneId);

        let depots = response.data.body

        if (depotSZoneId !== 'all') {
          depots=depots.filter(d => d.depotZone === depotSZoneId);
        }

        let parentDepotId=localStorage.getItem("parentDepotId");


        if (parentDepotId&&parseInt(parentDepotId) !== 0)
          depots = depots.filter(depot => depot.parentDepotId === parseInt(parentDepotId));

        setDepot(depots);


        // setZone(prevState => [
        //   ...prevState,
        //   { id: prevState.length + 1, name: 'All', key: 'all' }
        //  ]);

        var tempZones = [{ id: 1, name: 'All', key: 'all' }];
        response.data.body.forEach(depot => {
          const { depotZone } = depot;
          const existingZone = tempZones.find(item => item.key === depotZone);
          if (!existingZone) {
            tempZones.push({ id: tempZones.length + 1, name: depotZone, key: depotZone });

          }
        });
        setZone(tempZones);
      })
      .catch((e) => {
        // console.log('Cannot load event data', e);
      });
  };

  const check_prv_depot = () => {
    const prv_depotId = localStorage.getItem('selected_depot');
    if (prv_depotId) {
      setDepotId(prv_depotId);
      countDepotChargerVech(prv_depotId);
    }
  };

  const countDepotChargerVech = (depotId) => {
    api1
      .countDepotChargerVech(null, depotId)
      .then((response) => {
        setTotalVehicle(response.data.v_count);
        setTotalCharger(response.data.c_count);
      })
      .catch((e) => {
        console.log('Cannot load event data', e);
      });
  };

  const handleParentDepotId = (e) => {


    localStorage.setItem("parentDepotId", e.target.value);



    fetchDepotListZoneWise(depotZoneId)


  }

  return (

    <Grid container item
      lg={12} sm={12} md={12} xs={12}
       style={{ display: 'flex', justifyContent: 'center' }}>

      {/* <MatxMenu style={{ width: '100%' }} menuButton={
        <UserMenu style={{ width: '100%' }}>

          <Box
            component="fieldset"
            sx={{
              border: '2px solid #1976d2',
              borderRadius: '8px',
              position: 'relative',
              width: '100%',
              height: '70px'


            }}
          >
            <Grid
              component="legend"
              sx={{
                color: '#1976d2',
                fontWeight: 'bold',
              }}
            >
              Select Depot
            </Grid>

            <Grid>{depotName}</Grid>

          </Box>



        </UserMenu>
      }> */}

        <Grid item container spacing={1}>


          <Grid item lg={4} sm={4} md={4} xs={4} >


            {/* <StyledItem style={{ color: darkTheme ? '#F2E34C' : '#000', backgroundColor: darkTheme ? '#333333' : '#f9f9f9', width: '100%', }}> */}

              {zoneconfirmation && (
                <Box borderColor={darkTheme ? '#f2e34c' : '#000'} style={{ width: '100%', }} >
                  <FormControl fullWidth sx={{ width: "100%", height: "60%", borderColor: darkTheme ? '#f2e34c' : '#000' }}>
                    <InputLabel
                      id="Depot Zone"
                      style={{
                        color: darkTheme ? '#fff' : '#000',
                        fontSize: '18px',
                        borderColor: darkTheme ? '#f2e34c' : '#000',
                      }}
                    >
                      Zone
                    </InputLabel>
                    <Select
                      labelId="Depot Zone"
                      id="Depot Zone"
                      value={depotZoneId}
                      label="Zone ."
                      onChange={handleChangeZone}
                      fullWidth
                      style={{
                        color: darkTheme ? '#f2e34c' : '#000',
                        borderColor: '#ff0000',
                      }}
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: darkTheme ? 'white' : 'black',
                        }
                      }}

                      IconComponent={() => (
                        <ArrowDropDownIcon
                          style={{ color: darkTheme ? '#fff' : '#000' }}
                        />
                      )}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            backgroundColor: darkTheme ? '#000' : '#f9f9f9',
                            borderColor: '#ff0000',
                          },
                        },
                      }}
                      InputProps={{
                        style: {
                          color: 'white',
                          borderColor: '#ff0000', // Change border color to red
                        },
                      }}
                    >

                      {zone.map((c, index) => (
                        <MenuItem
                          style={{
                            display: "block",
                            padding: "10px",
                            color: darkTheme ? '#fff' : '#000',
                            backgroundColor: darkTheme ? '#000' : '#f9f9f9',
                            borderColor: '#ff0000', // Change border color to red
                          }}
                          value={c.key}
                          key={index}
                        >
                          {c.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )
              }

            {/* </StyledItem> */}

          </Grid>


          <Grid item lg={4} sm={4} md={4} xs={4} >

            {/* <StyledItem style={{ color: darkTheme ? '#F2E34C' : '#000', backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }}> */}



              <Box borderColor={darkTheme ? '#f2e34c' : '#000'} style={{ width: '100%', }} >
                <FormControl fullWidth sx={{ width: "100%", height: "60%", borderColor: darkTheme ? '#f2e34c' : '#000' }}>
                  <InputLabel
                    id="Depot"
                    style={{
                      color: darkTheme ? '#fff' : '#000',
                      fontSize: '18px',
                      borderColor: darkTheme ? '#f2e34c' : '#000',
                    }}
                  >
                    Parent Depot
                  </InputLabel>
                  <Select
                    labelId="Depot"
                    id="Depot"
                    value={parentDepotId}
                    label="Parent Depot ."
                    onChange={handleParentDepotId}
                    fullWidth
                    style={{
                      color: darkTheme ? '#f2e34c' : '#000',
                      borderColor: '#ff0000',
                    }}
                    sx={{
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: darkTheme ? 'white' : 'black',
                      }
                    }}

                    IconComponent={() => (
                      <ArrowDropDownIcon
                        style={{ color: darkTheme ? '#fff' : '#000' }}
                      />
                    )}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          backgroundColor: darkTheme ? '#000' : '#f9f9f9',
                          borderColor: '#ff0000',
                        },
                      },
                    }}
                    InputProps={{
                      style: {
                        color: 'white',
                        borderColor: '#ff0000', // Change border color to red
                      },
                    }}
                  >
                    {parentDepotList.map((depot, index) => (
                      <MenuItem
                        style={{
                          display: "block",
                          padding: "10px",
                          color: darkTheme ? '#fff' : '#000',
                          backgroundColor: darkTheme ? '#000' : '#f9f9f9',
                          borderColor: '#ff0000', // Change border color to red
                        }}
                        value={depot.id}
                        key={index}
                      >
                        {depot.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>


            {/* </StyledItem> */}
          </Grid>


          <Grid item lg={4} sm={4} md={4} xs={4} >


            {/* <StyledItem style={{ color: darkTheme ? '#F2E34C' : '#000', backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }}> */}


              <Box borderColor={darkTheme ? '#f2e34c' : '#000'} style={{ width: '100%', }} >
                <FormControl fullWidth sx={{ width: "100%", height: "60%", borderColor: darkTheme ? '#f2e34c' : '#000' }}>
                  <InputLabel
                    id="Depot"
                    style={{
                      color: darkTheme ? '#fff' : '#000',
                      fontSize: '18px',
                      borderColor: darkTheme ? '#f2e34c' : '#000',
                    }}
                  >
                    Depot
                  </InputLabel>
                  <Select
                    labelId="Depot"
                    id="Depot"
                    value={depotId}
                    label="Depot ."
                    onChange={handleChange}
                    fullWidth
                    style={{
                      color: darkTheme ? '#f2e34c' : '#000',
                      borderColor: '#ff0000',
                    }}
                    sx={{
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: darkTheme ? 'white' : 'black',
                      }
                    }}

                    IconComponent={() => (
                      <ArrowDropDownIcon
                        style={{ color: darkTheme ? '#fff' : '#000' }}
                      />
                    )}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          backgroundColor: darkTheme ? '#000' : '#f9f9f9',
                          borderColor: '#ff0000',
                        },
                      },
                    }}
                    InputProps={{
                      style: {
                        color: 'white',
                        borderColor: '#ff0000', // Change border color to red
                      },
                    }}
                  >
                    {depos.map((c, index) => (
                      <MenuItem
                        style={{
                          display: "block",
                          padding: "10px",
                          color: darkTheme ? '#fff' : '#000',
                          backgroundColor: darkTheme ? '#000' : '#f9f9f9',
                          borderColor: '#ff0000', // Change border color to red
                        }}
                        value={c.depotId}
                        key={index}
                      >
                        {c.depotName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            {/* </StyledItem> */}

          </Grid>

        </Grid>



      {/* </MatxMenu> */}



    </Grid>
  );
}